import styled, { css } from 'styles/styled'
import { Text } from 'Components/Typography'

const customerBorderRadius = css`
  border-radius: ${({ theme }) =>
    `0 ${theme.border.radius.regular} ${theme.border.radius.regular} ${theme.border.radius.regular}`};
`

const userBorderRadius = css`
  border-radius: ${({ theme }) =>
    `${theme.border.radius.regular} 0 ${theme.border.radius.regular} ${theme.border.radius.regular}`};
`
export const Container = styled.li<{ isCustomer: boolean }>`
  display: flex;
  justify-content: ${({ isCustomer }) =>
    isCustomer ? 'flex-start' : 'flex-end'};
`

export const Message = styled.div<{ isCustomer: boolean }>`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[5]}`};
  ${({ isCustomer }) => (isCustomer ? customerBorderRadius : userBorderRadius)}
  background-color: ${({ isCustomer, theme }) =>
    isCustomer ? theme.color.greyScale[50] : theme.color.primary[400]};

  .clear-link-style {
    color: inherit;
    text-decoration: none;
  }
`
export const MessageText = styled(Text)<{ isCustomer: boolean }>`
  color: ${({ isCustomer, theme }) =>
    isCustomer ? theme.color.black : theme.color.white};
`

export const imgContent = styled.img`
  max-width: 320px;
  max-height: 338px;
  cursor: pointer;
`;

export const videoContent = styled.video`
  max-width: 320px;
  max-height: 338px;
  cursor: pointer;
  position: relative;
`;

export const videoContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-black circle */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 20px solid white; /* Triangle color */
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    margin-left: 5px; /* Adjust to center the triangle */
    z-index: 2;
  }
`;

export const gifContent = styled.video`
  max-width: 320px;
  max-height: 338px;
  cursor: pointer;
  position: relative;
`;

export const gifContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }

  &::before {
    visibility: hidden;
    opacity: 0;
    content: 'GIF';
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 1;
  }
`;

export const FileContainer = styled.div`
  position: relative;
  cursor: pointer;
  padding: 14px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    text-decoration: none;
  }

  > div {
    width: 212px;
    margin: 0 16px 0 0;
  }

  svg path {
    color: #55606e;
  }

  .download-file-btn {
    width: 48px;
    height: 48px;
  }

  .file-icon {
    width: 36px;
    height: 36px;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  font-size: 11px;
`;

export const SingleEmojiComponent = styled.div`
  font-size: 48px;
`
import { IMessageModel } from 'models/messages/types'
import React, { useEffect, useRef, useState } from 'react'
import * as S from './MessageCard.styles'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MESSAGE_TYPE } from 'models/messages/enums'
import { format, isBefore, startOfDay } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { AudioPlayer } from 'react-audio-play'

export interface IMessageCardProps extends IMessageModel {
  messageText: string
  isCustomer: boolean
  setContent: (data: { url: string; type: 'video' | 'image' }) => void
}
export default function MessageCard({
  textMessage,
  isCustomer,
  contentLink,
  content,
  created_at,
  caption = 'IMG_2024232323_1212121.jpg',
  setContent,
}: IMessageCardProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoRefThumbnail = useRef<HTMLVideoElement>(null)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const [isGif, setIsGif] = useState<boolean>(false)

  useEffect(() => {
    if (content === MESSAGE_TYPE.VIDEO) {
      const video = videoRef.current

      if (video) {
        video.addEventListener('loadeddata', () => {
          if (video.videoWidth <= 321) {
            setIsGif(true)
          }

          video.currentTime = 1 // Seek to 1 second to capture the thumbnail
        })

        setVideoUrl(contentLink)
      }
    }
  }, [videoUrl, videoRef, content, contentLink])

  useEffect(() => {
    if (content === MESSAGE_TYPE.VIDEO && videoUrl) {
      const video = videoRefThumbnail.current

      if (video) {
        video.addEventListener('loadeddata', () => {
          video.currentTime = 1 // Seek to 1 second to capture the thumbnail
        })

        video.src = videoUrl
      }
    }
  }, [content, videoRefThumbnail, videoUrl])

  function LinkRenderer(props: any) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    )
  }

  const renderTime = () => {
    const today = startOfDay(new Date())
    const formatDate = isBefore(new Date(created_at), today) ? 'Pp' : 'H:mm'

    const formatedTime = format(new Date(created_at), formatDate, {
      locale: ptBR,
    })

    return <S.TimeContainer>{formatedTime}</S.TimeContainer>
  }

  const renderAudio = () => {
    return (
      <S.Container isCustomer={isCustomer}>
        <S.Message isCustomer={isCustomer}>
          <AudioPlayer src={contentLink} style={{ width: '336px' }} />
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const renderText = () => {
    const newMessageContent = (
      textMessage?.replace(/\n/gi, '  \n') || ''
    ).trim()
    const isOnlyEmoji = /^[\p{Emoji}]{1}$/u.test(textMessage || '')

    if (isOnlyEmoji) {
      return (
        <S.Container isCustomer={isCustomer}>
          <S.Message isCustomer={isCustomer}>
            {/* Render the component for single emoji */}
            <S.SingleEmojiComponent>{newMessageContent}</S.SingleEmojiComponent>
            {renderTime()}
          </S.Message>
        </S.Container>
      )
    }

    return (
      <S.Container isCustomer={isCustomer}>
        <S.Message isCustomer={isCustomer}>
          {/* <S.MessageText isCustomer={isCustomer} content={messageText} /> */}
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{ a: LinkRenderer }}
          >
            {newMessageContent}
          </Markdown>
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const renderImg = () => {
    return (
      <S.Container isCustomer={isCustomer}>
        <S.Message
          isCustomer={isCustomer}
          onClick={() =>
            setContent({
              url: contentLink,
              type: 'image',
            })
          }
        >
          <S.imgContent src={contentLink} alt="" />
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const renderVideo = () => {
    return (
      <S.Container isCustomer={isCustomer} data-created-at={created_at}>
        <S.Message isCustomer={isCustomer}>
          <S.videoContainer
            onClick={() =>
              setContent({
                url: contentLink,
                type: 'video',
              })
            }
          >
            <S.videoContent
              autoPlay={false}
              muted={true}
              controls={false}
              ref={videoRefThumbnail}
            />
          </S.videoContainer>
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const renderGift = () => {
    return (
      <S.Container isCustomer={isCustomer}>
        <S.Message isCustomer={isCustomer}>
          <S.gifContainer>
            <S.gifContent src={videoUrl} autoPlay={true} loop={true} />
          </S.gifContainer>
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const renderFile = () => {
    return (
      <S.Container isCustomer={isCustomer}>
        <S.Message isCustomer={isCustomer}>
          <a
            href={contentLink}
            target="_blank"
            rel="noreferrer"
            className="clear-link-style"
          >
            <S.FileContainer>
              <svg
                className="file-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M13 9V3.5L18.5 9M6 2c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
              </svg>

              <div>{caption || 'IMG_2024232323_1212121.jpg'}</div>

              <svg
                className="download-file-btn"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m12 15.577l-3.539-3.538l.708-.72L11.5 13.65V5h1v8.65l2.33-2.33l.709.719zM5 19v-4.038h1V18h12v-3.038h1V19z"
                />
              </svg>
            </S.FileContainer>
          </a>
          {renderTime()}
        </S.Message>
      </S.Container>
    )
  }

  const render = () => {
    if (content === MESSAGE_TYPE.VIDEO) {
      if (isGif) {
        return renderGift()
      }

      if (!isGif && videoUrl) {
        return renderVideo()
      }
    }

    if (content === MESSAGE_TYPE.IMAGE) {
      return renderImg()
    }

    if (content === MESSAGE_TYPE.AUDIO) {
      return renderAudio()
    }

    if (content === MESSAGE_TYPE.FILE) {
      return renderFile()
    }

    return renderText()
  }

  return (
    <>
      {content === MESSAGE_TYPE.VIDEO ? (
        <video
          ref={videoRef}
          controls
          style={{ display: 'none' }}
          src={videoUrl}
        />
      ) : null}
      {render()}
    </>
  )
}

import { useCallback, useEffect, useState, useRef } from 'react'
import { ManagerOptions, SocketOptions, io } from 'socket.io-client'
import type { Socket } from 'socket.io-client'
import { useMessage } from 'models/messages/hooks'
import { useAppSelector } from 'store'

export function SocketConnection() {
  const { updateMessageFromUser, updateChannel, handleNewChannel } =
    useMessage()
  const { data: userData } = useAppSelector((state) => state.user)

  const [socket, setSocket] = useState<Socket>(null)

  let conn = useRef<Socket>(null)

  const handleSocketConnection = useCallback(() => {
    const url = `${process.env.REACT_APP_URL_API_ORBIT_SOCKET}`

    const options: Partial<ManagerOptions & SocketOptions> = {
      query: {
        userId: userData?.id,
        externalId: userData?.externalId,
      },
      transports: ['websocket'],
      withCredentials: true,
    }

    if (!conn.current) {
      conn.current = io(url, options)
      setSocket(conn.current)
    }
  }, [userData?.externalId, userData?.id])

  useEffect(() => {
    handleSocketConnection()
  }, [handleSocketConnection])

  useEffect(() => {
    if (socket) {
      socket.on('connect_error', (err) => {
        console.log('connect_error', JSON.stringify(err))
      })

      socket.on('error', (err) => {
        console.error('error: ', err)
      })

      socket.on('connect_failed', function (err) {
        console.log('connection error: ', err)
      })

      socket.on('recMessage', (message) => {
        updateMessageFromUser(message)
      })

      socket.on('newMessageFromCustomer', (message) => {
        updateChannel(message)
      })

      socket.on('newChannel', (channel) => {
        handleNewChannel(channel)
      })
    }

    // eslint-disable-next-line
  }, [socket])

  return <></>
}

import React, { useEffect } from 'react'
import * as S from './midiaModal.styles'

type MidiaModalProps = {
  url: string
  type: 'image' | 'video'
  onClose: () => void
}

const MidiaModal: React.FC<MidiaModalProps> = ({ url, type, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <S.ModalContainer className="modal">
      {/* Header */}
      <S.ModalHeader>
        <S.CloseButton onClick={onClose}>X</S.CloseButton>
      </S.ModalHeader>
      <S.ModalContent>
        {type === 'image' && <S.ImageContainer src={url} alt="Image" />}
        {type === 'video' && (
          <S.VideoContainer controls autoPlay={false}>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </S.VideoContainer>
        )}
      </S.ModalContent>
    </S.ModalContainer>
  )
}

export default MidiaModal

import { useCallback, useEffect, useState } from 'react'
import MessageCard from './parts/MessageCard'
import MidiaModal from './parts/MidiaModal'
import * as S from './MessageList.styles'
import { useMessage } from 'models/messages/hooks'
import { IMessageModel } from 'models/messages/types'

export default function MessageList() {
  const { data, divRef } = useMessage()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [contentUrl, setContentUrl] = useState<string | undefined>()
  const [contentType, setContentType] = useState<'image' | 'video'>('image')

  useEffect(() => {
    console.info('MSGS ', data)
  }, [data])

  const setContent = ({
    type,
    url,
  }: {
    url: string
    type: 'video' | 'image'
  }) => {
    setContentUrl(url)
    setContentType(type)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setContentUrl(undefined)
    setContentType('image')
  }

  const renderItems = useCallback((data) => {
    return data?.map((item: IMessageModel) => ( 
      <MessageCard
        key={item?.id}
        isCustomer={item?.sender === 2}
        messageText={item?.textMessage}
        setContent={setContent}
        {...item}
      />
    ))
  }, [])

  return (
    <S.MessageListContainer>
      {showModal && (
        <MidiaModal type={contentType} url={contentUrl} onClose={closeModal} />
      )}
      {renderItems(data)}
      <div ref={divRef} />
    </S.MessageListContainer>
  )
}

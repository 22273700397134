import styled from 'styles/styled'

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9999;
  padding: 20px 40px;
  backdrop-filter: blur(10px);
`;

export const ModalHeader = styled.header`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const MediaContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const VideoContainer = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const ModalContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.button`
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-size: 30px;
`;
;

export enum LINK_SERVICE_TYPES {
  BOT_MAKER = 0,
}

export enum MESSAGE_ORIENTATION {
  RECEIVED = 0,
  SENDED = 1,
  SEND_FAILED = 2,
}

export enum MESSAGE_TYPE {
  TEXT = 0,
  AUDIO = 1,
  VIDEO = 2,
  IMAGE = 3,
  FILE = 4,
}

export enum MESSAGE_STATUS {
  PENDING = 0,
  RECEIVED = 1,
  READED = 2,
  FAILED = 3,
  SENDED = 4,
  SEND_FAILED = 5,
}

export enum MESSAGE_SENDER {
  USER = 1,
  CUSTOMER = 2,
  SYSTEM = 3,
}

import { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { batch } from 'react-redux'
import { FullPageLoader } from '../../Components/Loader'
import { useUser } from '../../models/user/hooks'
import { useApp } from '../../models/app/hooks'

import * as S from './Splash.styles'
import { persistor, useAppDispatch } from 'store'
import { fetchAllUsers } from 'models/user/services'
import { fetchChannels } from 'models/channel/services'

function SplashScreen() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  let fired = useRef<number>(0)

  const [searchParams] = useSearchParams()

  const {
    handleParams,
    error: userError,
    status: userStatus,
    data: userData,
  } = useUser()

  const {
    bootstrapApp,
    handleReset,
    error: appError,
    status: appStatus,
  } = useApp()

  useEffect(() => {
    const userName = searchParams.get('userName')
    const userID = searchParams.get('userID')

    console.log('fired')
    console.info('Password', process.env.REACT_APP_ORBIT_PASSWORD)
    if (fired.current === 0) {
      fired.current = 1
      batch(() => {
        handleReset()
        bootstrapApp().then(async () => {
          const userAuth = (
            (await handleParams({ userName, externalID: userID }))
              ?.payload as any
          )?.data

          console.info('userAuth', userAuth)

          if (userName !== userAuth?.userName) {
            persistor.purge()
            return navigate('/')
          }

          if (userName === userAuth?.userName && userAuth?.registered) {
            await dispatch(fetchAllUsers())

            await dispatch(
              fetchChannels({
                userId: userData?.id,
                params: {
                  messages: true,
                  unreadMessages: true,
                },
              })
            )

            navigate('/main')
          }
        })
      })
    }
  }, [bootstrapApp, handleParams, handleReset, searchParams, dispatch])

  useEffect(() => {
    // const userName = searchParams.get('userName')
    // if (userName !== userData?.userName) {
    //   persistor.purge()
    //   return navigate('/')
    // }
    // if (userName === userData?.userName && userData?.registered) {
    //   dispatch(fetchAllUsers())
    //   dispatch(
    //     fetchChannels({
    //       userId: userData?.id,
    //       params: {
    //         messages: true,
    //         unreadMessages: true,
    //       },
    //     })
    //   )
    //   navigate('/main')
    // }
  }, [userData, userError, userData?.registered, userData?.id, searchParams])

  if (appStatus === 'error' && appError)
    return (
      <S.Wrapper>
        <S.Text>Ocorreu um erro ao carregar sistema.</S.Text>
      </S.Wrapper>
    )

  if (userStatus === 'error' && userError)
    return (
      <S.Wrapper>
        <S.Text>Ocorreu um erro ao efetuar login no sistema.</S.Text>
      </S.Wrapper>
    )

  return <FullPageLoader loading={true} />
}

export default SplashScreen

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IBotMakerAgentRequestData } from 'models/app/types'
import { api } from 'services/client'
import { IUserRequestHeaders } from './types'
import { IFetchUserResponse, IFetchUsersResponse } from './types'

export const fetchUser = createAsyncThunk<
  IFetchUserResponse,
  IUserRequestHeaders
>('user/getData', async (data, thunkAPI) => {
  const response = await api.get<IFetchUserResponse>(
    'cx-messages-service/api/users/username',
    {
      params: {
        userName: data.userName,
      },
    }
  )

  return response.data
})

export const fetchAllUsers = createAsyncThunk<IFetchUsersResponse, undefined>(
  'user/getAll',
  async (_, thunkAPI) => {
    const response = await api.get<IFetchUsersResponse>(
      'cx-messages-service/api/users/tenantid/getAll',
      {
        params: { active: 1 },
      }
    )

    console.log("response.data", response.data)
    return response.data
  }
)

export const setUserStatus = createAsyncThunk<
  IFetchUserResponse,
  { id; userStatus }
>('user/updateStatus', async ({ id, userStatus }, thunk) => {
  const response = await api.put<IFetchUserResponse>(
    `cx-messages-service/api/users/status/${id}`,
    {
      userStatus,
    }
  )

  return response.data
})

export const createBotMakerAgent = createAsyncThunk<
  string,
  IBotMakerAgentRequestData
>('app/createAgent', async (data, thunkApi) => {
  const response = await api.post(
    'cx-messages-service/api/users/createLinkServiceAgent',
    data
  )
  return response.data
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import { IAppRequest, IAppModel } from './types'

export const appAuth = createAsyncThunk<IAppModel, IAppRequest>(
  'app/auth',
  async (data, thunkAPI) => {
    const response = await api.post<IAppModel>('/auth', data)
    sessionStorage.setItem('token', response.data.token)

    return response.data
  }
)
